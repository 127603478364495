.GlobalTable {

}

.serversBlock {
    margin-left: auto;
    margin-right: auto;
    width: 600px;
}

.titleTable {
    color: #fce48c;
    float: left;
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-right: 2px solid #fce48c;
}

.titleTableTar {
    color: #a9bafd;
    float: left;
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-right: 2px solid #fce48c;
}

.formServer {
    display: inline-block;
    float: left;
}

.serverColum {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    /*margin-top: 10px;*/
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    font-size: 0.9rem;
    padding-top: 12px;
}

.serverColumC {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    /*margin-top: 10px;*/
    text-align: center;
    border-right: 1px solid #5a5a5a;
    font-size: 0.9rem;
    padding-top: 12px;
}

.serverColumYesterday {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    font-size: 0.9rem;
    padding-top: 12px;
}

.serverColumYesterdayBlockB {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    font-size: 0.9rem;
    /*padding-top: 5px;*/
}

.serverColumYesterdayButton {
    color: #fdfde7;
    cursor: pointer;
    width: 95%;
    height: 95%;
    font-size: 0.9rem;
}

.serverButtonTitle {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    /*margin-top: 10px;*/
}

.formButton {
    float: left;
    background-color: rgba(138, 43, 226, 0.14);
    color: white;
    width: 99%;
    height: 80%;
    margin-top: 5px;
    cursor: pointer;
}

.formButtonAlert {
    float: left;
    background-color: rgba(138, 43, 226, 0.14);
    color: red;
    width: 99%;
    height: 80%;
    margin-top: 5px;
    cursor: pointer;
}

.spanText {
    /*padding-top: 15px;*/

}

.spanTextOn {
    /*padding-top: 15px;*/
    color: #00c739;
}

.spanTextAlert {
    /*padding-top: 15px;*/
    color: crimson;
}

.spanTextWarning {
    /*padding-top: 15px;*/
    color: hotpink;
}

.serverDateUpdate {
    display: flex;
    border-bottom: 1px solid #5a5a5a;
    margin-top: 15px;
    float: left;
    color: lightslategrey;
    font-size: 0.7rem;
    width: 600px;
}


.Modal_title {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 100px;
    margin-right: 10px;
    color: chocolate;

}

.Modal_Button {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    text-align: center;

}

.Save_Button {
    margin: 0 5px;


    cursor: pointer;

}
