.Global {

    height: calc(100vh - 100px);


}

.globalBlock {
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.25), 0 5px 10px rgb(0, 85, 174);
    background-color: #003164;

}

.HomeBlock {
    margin-top: 5px;
    height: calc(100vh - 120px);
    display: block;
    width: 100%;
    border: 1px solid #3f3f42; /* размер и цвет границы блока */
    overflow: auto;
}

.HeaderBlock {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 5px;
    font-size: 16px;
    color: #f1f2f2;
    border: 1px solid #30353b; /* размер и цвет границы блока */
}

.HomeRow {
    align-items: flex-start;
    height: 500px;
    width: 100%;
}

.widgetBlock {
    float: left;
    width: 49%;
    display: inline-block;
    margin: 4px;
}

.OrderBlock {
    align-items: center;
    margin: 4px;
    display: inline-block;
    width: 49%;
}

.widgetTicker {
    align-items: flex-start;
    display: block;
    margin: 0 5px 0 5px;
    background-color: #0b5aab;
}


