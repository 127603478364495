.App {
  text-align: center;
}

.App-header {
  background-color: #4682B4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  /*//font-size: calc(10px + 2vmin);*/

}

.App-link {
  color: #b5cae1;
}

