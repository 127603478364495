.headerImg {
    margin-left: 20px;
    height: 60px;
}

.exit {
    margin-right: 10px;
}

.auth {
    border-left: 1px solid #c1c1c1;
    border-right: 1px solid #c1c1c1;
    float: right;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    padding: 0 10px;
    margin: 2px 5px 0 0;

}

.icon_auth_admin {
    color: #00c739;
    padding: 5px;
}

.icon_auth_user {
    color: #ffffcc;
    padding: 5px;
}

.icon_auth_client {
    color: #00ecff;
    padding: 5px;
}

.iconMenu {
    font-size: 16px;
    margin: 4px 4px 0 4px;

}

.NameMenu {
    text-align: center;
    display: flex;
}

.InfoProgram {

    text-align: left;

}

.Modal_title {
    font-size: 20px;
    margin-left: 35px;
    margin-top: 100px;
    color: chocolate;
}

.Modal_Button {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    text-align: center;
}

.Save_Button {
    margin: 0 5px;

}
.headerB{
    background-color: #002851;
}
.Timer{
    margin-left: 15px;

    color: #fce48c;
    display: flex;

}