
.spinner {
    text-align: center;
    padding-top: 5%;
}

.formLogImg {
    margin-top: 5%;
    margin-bottom: 20px;
    height: 150px;
    opacity: 0.4;
}


