body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

div::-webkit-scrollbar-thumb {
    background: #0568ce;
    border-radius: 16px;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

div::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #044d97, #0568ce 1px, #1c1c1c 0, #232323);
}

div::-webkit-scrollbar-corner {
    background: #0568ce;
}