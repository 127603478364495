.GlobalTable {
    overflow: auto;
    height: calc(100vh - 200px);
}

.Table {
    color: #fce48c;
    overflow: hidden;
}

.serversBlock {
    margin: 10px 10px;

}

.titleTable {
    color: #fce48c;
    float: left;
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-right: 2px solid #fce48c;
}

.titleMount {
    color: #fce48c;
    font-size: 17px;
}

.titleTableTar {
    color: #a9bafd;
    float: left;
    width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-right: 2px solid #fce48c;
}

.formServer {
    display: inline-block;
    float: left;
}

.serverColum {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    /*margin-top: 10px;*/
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    font-size: 0.9rem;
    padding-top: 12px;
}

.serverColumC {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    /*margin-top: 10px;*/
    text-align: center;
    border-right: 1px solid #5a5a5a;
    font-size: 0.9rem;
    padding-top: 12px;
}

.serverColumYesterday {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    font-size: 0.9rem;
    padding-top: 12px;
}

.serverColumYesterdayBlockB {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    font-size: 0.9rem;
    /*padding-top: 5px;*/
}

.serverColumYesterdayButton {
    color: #fdfde7;
    cursor: pointer;
    width: 95%;
    height: 95%;
    font-size: 0.9rem;
}

.serverButtonTitle {
    color: #ededed;
    float: left;
    width: 100px;
    height: 50px;
    text-align: center;
    /*border-right: 1px solid #5a5a5a;*/
    /*margin-top: 10px;*/
}

.formButton {
    float: left;
    background-color: rgba(138, 43, 226, 0.14);
    color: white;
    width: 99%;
    height: 80%;
    margin-top: 5px;
    cursor: pointer;
}

.formButtonH {
    margin-left: 10px;
    background-color: rgba(138, 43, 226, 0.14);
    color: white;
    cursor: pointer;
}

.formButtonAlert {
    float: left;
    background-color: rgba(138, 43, 226, 0.14);
    color: red;
    width: 99%;
    height: 80%;
    margin-top: 5px;
    cursor: pointer;
}

.spanText {
    /*padding-top: 15px;*/

}

.spanTextOn {
    /*padding-top: 15px;*/
    color: #00c739;
}

.spanTextAlert {
    /*padding-top: 15px;*/
    color: crimson;
}

.spanTextWarning {
    /*padding-top: 15px;*/
    color: hotpink;
}

.serverDateUpdate {
    display: flex;
    border-bottom: 1px solid #5a5a5a;
    margin-top: 15px;
    float: left;
    color: lightslategrey;
    font-size: 0.7rem;
    width: 600px;
}


.Modal_title {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 100px;
    margin-right: 10px;
    color: chocolate;

}

.Modal_Button {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    text-align: center;

}

.Save_Button {
    margin: 0 5px;
    cursor: pointer;

}

.Tr {
    color: gainsboro;
    text-align: center;
}

.TdR {
    color: #6bb5ff;
    width: 50%;
    text-align: center;
    border-right: 1px solid #eeffff;
    font-style: italic;
    padding-right: 5px;
}

.TdL {
    color: #6bb5ff;
    width: 50%;
    text-align: center;
    font-style: italic;
    padding-left: 5px;
}

.TdkWRDate {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 1em;
    color: #b799c1;
    width: 50%;
    text-align: left;
    border-right: 1px solid #dadada;
}

.TdkWRDay {
    font-size: 0.9em;
    color: #dadada;
    width: 60%;
    text-align: left;
    border-right: 1px solid #dadada;
}

.TdkWLDay {
    font-size: 0.9em;
    padding-left: 10px;
    color: #dadada;
    width: 50%;
    text-align: left;
}

.TdkWR {
    color: #f0fff0;
    width: 50%;
    text-align: center;
    border-right: 1px solid #eeffff;
}

.TdkWL {
    color: #f0fff0;
    width: 50%;
    text-align: center;
}

.TdkItogo {
    font-size: 0.9em;
    color: #00FFFF;
    text-align: center;
    border-right: 1px solid #eeffff;
}

.TdkItogoSpan {
    font-size: 0.95em;
    color: rgba(0, 255, 255, 0.6);
    text-align: center;

}




