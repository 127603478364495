body {
    font-family: Arial, Helvetica, sans-serif;
    /*padding-top: 5px;*/
    /*background: url(fon_png24.png) no-repeat bottom left fixed;*/
    color: #f1f2f2;

}
input[type="checkbox"] { /* change "blue" browser chrome to yellow */
    filter: invert(85%) hue-rotate(18deg) brightness(1.7);
}

.formInput {
    font-size: 14px;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    border: 1px solid #c7c7c7;
    border-radius: 20px;
    margin-bottom: 5px;
    background-color: rgba(138, 43, 226, 0.14);
    /*background: rgba(255, 255, 255, .2);*/
}

.formLogin {
    box-shadow: 0 5px 28px rgba(0,0,0,0.25), 0 5px 10px rgb(5, 104, 206);
    border: 2px solid #c7c7c7;
    border-radius: 10px;
    /*width: 400px;*/
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #003f7f;
}

.formLogLabel {
    font-size: 16px;
    color: #f1f2f2;
    padding: 10px 15px;
    float: left;
}

.formLogButton {
    background-color: rgba(138, 43, 226, 0.14);
    color: white;
    padding: 14px 10px;
    margin: 8px 0;
    cursor: pointer;
    width: 100%;
}

.formLogCheckboxLabel {

    font-size: 14px;
    color: #f1f2f2;
    padding: 0 5px 0 0;
    float: left;
}

.formLogCheckbox {
    float: left;
}

.formDivCheckbox {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.formGlobal {
    width: 350px;
}

.formLogImg {
    margin-top: 5%;
    margin-bottom: 20px;

    width: 100%;
}

.formButtonSpinner {
    padding-right: 10px;
}

.formDIV{
    background-color: #044d97;
}


