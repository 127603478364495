


input[type="checkbox"] { /* change "blue" browser chrome to yellow */
    filter: invert(85%) hue-rotate(18deg) brightness(1.7);
}


.TitleTable {
    font-size: 22px;
    float: left; /*Задаем обтекание*/
    color: #fce48c;
    margin: 10px 10px 0 10px;
}

.Modal_title {
    color: #fce48c;
    font-size: 18px;
}

.Modal_close_icon {
    color: #fce48c;
    font-size: 18px;
}

.Modal_form {
    background-color: #3f3f42;

}
.tableConfirm {
    color: #3f3f42;
}

.divUsersBlock {
    margin: 10px;
}

.tableUsers {
    padding: 10px;
    overflow: auto;
    height: calc(100vh - 250px);
    width: auto;
}

.tableName {
    font-weight: bold;
    color: #dcdcdc;
}

.tableCode {
    color: #cacaca;
    font-weight: bold;
}

.tableStatus {
    text-align: center;
}

.imgStatus {
    width: 20px;
    margin-left: 5px;
    margin-bottom: 3px;
    float: left;
}

.divButtonStatus {
    align-items: flex-start;
}

.divTitleTable {
    text-align: left;
}

.divButtonOK {
    display: inline-block;
    margin: 10px;
    color: #f1f2f2;
}

.divSpiner {
    text-align: center;
    margin-top: 20px;
    width: 100%;
}

.iconMenuButton {
    font-size: 22px;
    margin-right: 5px;

}
.formModalForm {
    margin-top: 20px;
    font-size: 16px;
    color: #f1f2f2;
    padding: 10px 15px;
    float: left;
}

.formModalAlert {
    margin-top: 10px;
}

.formAddUser {
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.22);
    border: 2px solid #c7c7c7;
    border-radius: 10px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 2px;
}

.formLogLabel {
    font-size: 16px;
    color: #f1f2f2;
    padding: 10px 15px;
    float: left;
}

.formInput {
    color: #f1f2f2;
    font-size: 14px;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    border: 1px solid #c7c7c7;
    border-radius: 20px;
    margin-bottom: 5px;
    background-color: rgba(138, 43, 226, 0.14);
    /*background: rgba(255, 255, 255, .2);*/
}

.formInputDisabled {
    color: #fce48c;
    font-size: 14px;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    border: 1px solid #c7c7c7;
    border-radius: 20px;
    margin-bottom: 5px;
    background-color: rgba(138, 43, 226, 0.14);
    /*background: rgba(255, 255, 255, .2);*/
}

.formOptionsInput {
    padding: 5px;
    color: #fce4bf;
    background-color: rgba(25, 5, 40, 0.84);
    border: 2px;
    outline: none;
    font-size: 16px;
}

.formInputSelect {
    color: #f1f2f2;
    font-size: 14px;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    border: 1px solid #c7c7c7;
    border-radius: 20px;
    margin-bottom: 5px;
    background-color: rgba(138, 43, 226, 0.14);
    cursor: pointer;
    line-height: 1.1;

    /*background: rgba(255, 255, 255, .2);*/
}

.formUserButton {
    background-color: rgba(138, 43, 226, 0.14);
    color: white;
    padding: 14px 10px;
    margin: 8px 0;
    cursor: pointer;
    width: 100%;
}

.formButtonSpinner {
    padding-right: 10px;
}
